import {$} from "./utils.js";
import {lang} from "../lang.js";
import {Form} from "./form.js";

window.inputs={};

export class Scene {
    constructor(form) {
        const that = this;
        this.form = form;
        this.add = {
            input: function (data = {
                id: "",
                type: "text",
                value: "",
                checked: false,
                disabled: false,
                required: false,
                multiple: false,
                placeholder: "",
                min: "",
                max: "",
                onchange: function () {
                },
                oninput: function () {
                },
                onfocus: function () {
                },
                onfocusout: function () {
                },
                prepend: "",
                append: "",
                error: "",
                class: "",
                data: []
            }, form) {
                $(data).extends({
                    id: "",
                    type: "text",
                    value: "",
                    checked: false,
                    disabled: false,
                    required: false,
                    multiple: false,
                    placeholder: "",
                    min: "",
                    max: "",
                    onchange: function () {
                    },
                    oninput: function () {
                    },
                    prepend: "",
                    append: "",
                    error: "",
                    class: "",
                    data: []
                });
                if (!data.id) return false;
                const label = document.createElement("label");
                const input = document.createElement("input");
                const container = document.createElement("div");
                container.append(input);

                $(input).data("id", data.id);
                input.name = data.id;
                input.type = data.type;
                if(data.checked) input.checked = data.checked;
                if(input.type!="file") input.value = data.value;
                input.min = data.min;
                input.max = data.max;
                input.disabled = data.disabled;
                if (data.multiple) input.multiple = data.multiple;
                if (input.type == "radio") {
                    if (typeof (that.form.data[data.id]) != "undefined") {
                        if (input.value == that.form.data[data.id]) {
                            input.checked = true;
                        } else {
                            input.checked = false;
                        }
                    }
                } else if (input.type == "checkbox") {
                    if (that.form.data[data.id]) {
                        if (that.form.data[data.id].constructor == Array) {
                            let result = false;
                            that.form.data[data.id].each(function () {
                                if (input.value == this) {
                                    result = true;
                                }
                            });
                            input.checked = result;
                        } else {
                            input.checked = true;
                        }
                    }
                } else {
                    if (that.form.data[data.id] && that.form.data[data.id].constructor == Array) {
                        input.value = data.value;
                    } else {
						if(input.type=="file"){
							if(that.form.data[data.id]){
								const list=new DataTransfer();
								that.form.data[data.id].arrayBuffer().then(function(res){
									list.items.add(new File([res],that.form.data[data.id].name,that.form.data[data.id]));
									input.files = list.files;
									$(input).trigger("change");
								});
							}
						}else{
							input.value = that.form.data[data.id] || data.value;
						}
                    }
                }
                if (data.required) input.required = data.required;
                input.error = data.error;
                $(input).attr("placeholder", lang.translate(data.placeholder));
                $(input).on("change", data.onchange);
                $(input).on("focus", data.onfocus);
                $(input).on("focusout", data.onfocusout);
                $(input).on("change", function () {
                    that.form.JSON();
                });
                $(input).on("input", data.oninput);
                label.append(input);
                if (data.type == "checkbox") {
                    label.className = "checkbox";
                    $(label).append(`<span class="mark"></span>`);
                }
                if (data.class != "") label.className = data.class;
				$(label).addClass(`label-${data.type}`);

                $(label).prepend(data.prepend);
                $(label).append(data.append);
                if (form) {
                    form.append(label);
                } else {
                    that.form.target.append(label);
                }
                if (input.type != "radio" && (that.form.data[data.id] && that.form.data[data.id].constructor != Array)) {
                    $(input).trigger("change");
                    $(input).trigger("input");
                }

                return {label, input};
            },
            textarea: function (data = {
                id: "", value: "", placeholder: "", required: false, onchange: function () {
                }, oninput: function () {
                }, prepend: "", append: "", error: "", class: ""
            }, form) {
                $(data).extends({
                    id: "", value: "", placeholder: "", required: false, onchange: function () {
                    }, oninput: function () {
                    }, prepend: "", append: "", error: "", class: ""
                });
                const label = document.createElement("label");
                const textarea = document.createElement("textarea");
                $(textarea).data("id", data.id);
                textarea.name = data.id;
                textarea.value = data.value || that.form.data[data.id] || "";
                if (data.required) textarea.required = data.required;
                textarea.error = data.error;

                $(textarea).attr("placeholder", lang.translate(data.placeholder));
                $(textarea).on("change", data.onchange);
                $(textarea).on("change", function () {
                    that.form.JSON();
                });
                $(textarea).on("input", data.oninput);
                label.append(textarea);
                if (data.class != "") label.className = data.class;
                $(label).prepend(data.prepend);
                $(label).append(data.append);
                if (form) {
                    form.append(label);
                } else {
                    that.form.target.append(label);
                }

                return {label, textarea};
            },

            select: function (data = {
                id: "",
                values: {},
                checked: "",
                disabled: false,
                required: false,
                multiple: false,
                onchange: function () {
                },
                onfocus: function () {
                },
                onfocusout: function () {
                },
                prepend: "",
                append: "",
                error: "",
                class: "",
                data: []
            }, form) {
                $(data).extends({
                    id: "",
                    values: {},
                    checked: "",
                    disabled: false,
                    required: false,
                    multiple: false,
                    placeholder: "",
                    min: "",
                    max: "",
                    onchange: function () {
                    },
                    onfocus: function () {
                    },
                    onfocusout: function () {
                    },
                    prepend: "",
                    append: "",
                    error: "",
                    class: "",
                    data: []
                });

                if (!data.id) return false;
                const label = document.createElement("label");
                const select = document.createElement("select");
                const container = document.createElement("div");
                container.append(select);

                $(select).data("id", data.id);
                select.name = data.id;
                // select.checked=data.checked;
                select.value = data.value;
                select.disabled = data.disabled;
                if (data.multiple) select.multiple = data.multiple;
                select.error = data.error;
                if (data.class != "") label.className = data.class;

                let html = "";
                for (const key in data.values) {
                    const value = data.values[key];
                    if (value) {
                        if (Array.isArray(value) && value.length > 0) {

                            html +=/*html*/`
								<optgroup label="${key}">
							`;
                            for (const key2 of value) {
                                if (data.checked == key2) {
                                    html +=/*html*/`
								<option value="${key2}" selected>${key2}</option>
							`;
                                } else {
                                    html +=/*html*/`
								<option value="${key2}">${key2}</option>
							`;
                                }

                            }
                            html +=/*html*/`
								</optgroup>
							`;

                        } else {
                            if (data.checked == key) {
                                html +=/*html*/`
								<option value="${key}" selected>${key}</option>
							`;
                            } else {
                                html +=/*html*/`
								<option value="${key}">${key}</option>
							`;
                            }
                        }
                    } else {
                        if (data.checked == key) {
                            html +=/*html*/`
								<option value="${key}" selected>${key}</option>
							`;
                        } else {
                            html +=/*html*/`
								<option value="${key}">${key}</option>
							`;
                        }
                    }
                }
                select.innerHTML = html;

                $(select).on("change", data.onchange);
                $(select).on("focus", data.onfocus);
                $(select).on("focusout", data.onfocusout);
                $(select).on("change", function () {
                    that.form.JSON();
                });

                $(label).prepend(data.prepend);
                $(label).append(data.append);
                label.append(select);

                if (form) {
                    form.append(label);
                } else {
                    that.form.target.append(label);
                }

                return {label, select};
            },


			/**
			 * @param {{
			 * value: string,
			 * prepend: string,
			 * append: string,
			 * error: string,
			 * class: string,
			 * onclick:function
			 * }} data
			 * @param {Form} form
			 */
            button: function (data = {
                value: "Click me!",
                prepend: "",
                append: "",
                error: "",
                class: "",
				disabled:false,
                onclick: function () {
                }
            }, form) {
                $(data).extends({
                    value: "Click me!", prepend: "", append: "", error: "", class: "", disabled:false, onclick: function () {
                    }
                });
                const label = document.createElement("label");
                const button = document.createElement("button");
                label.className = data.class;
                button.innerHTML = data.value;
                button.error = data.error;
                $(button).on("click", data.onclick);
				if(data.disabled) button.disabled=data.disabled;

                label.append(button);
                $(label).prepend(data.prepend);
                $(label).append(data.append);

                if (form) {
                    form.append(label);
                } else {
                    that.form.target.append(label);
                }


                return {label, button};
            },

            container: function (data = {prepend: "", append: "", class: ""}, form) {
                $(data).extends({prepend: "", append: "", class: ""}, form);
                const div = document.createElement("div");
                if (data.class != "") div.className = data.class;
                const self = {
                    target: div,
                    add: {
                        input: function (data = {
                            id: "",
                            type: "text",
                            value: "",
                            checked: false,
                            disabled: false,
                            required: true,
                            multiple: false,
                            placeholder: "",
                            min: "",
                            max: "",
                            onchange: function () {
                            },
                            oninput: function () {
                            },
                            onfocus: function () {
                            },
                            onfocusout: function () {
                            },
                            prepend: "",
                            append: "",
                            error: "",
                            class: ""
                        }) {
							if(!window.inputs[data.id]){
								window.inputs[data.id]=[];
								window.inputs[data.id].push(data.value);
							}else{
								if(!window.inputs[data.id].includes(data.value)){
									window.inputs[data.id].push(data.value);
								}
							}
                            return that.add.input(data, div);
                        },
                        select: function (data = {
                            id: "",
                            values: {},
                            checked: "",
                            disabled: false,
                            required: false,
                            multiple: false,
                            onchange: function () {
                            },
                            onfocus: function () {
                            },
                            onfocusout: function () {
                            },
                            prepend: "",
                            append: "",
                            error: "",
                            class: "",
                            data: []
                        }) {
							if(!window.inputs[data.id]){
								window.inputs[data.id]=[];
								window.inputs[data.id].push(data.values);
							}else{
								if(!window.inputs[data.id].includes(data.values)){
									window.inputs[data.id].push(data.values);
								}
							}
                            return that.add.select(data, div);
                        },
                        textarea: function (data = {
                            id: "",
                            value: "",
                            placeholder: "",
                            required: true,
                            onchange: function () {
                            },
                            oninput: function () {
                            },
                            prepend: "",
                            append: ""
                        }) {
							if(!window.inputs[data.id]){
								window.inputs[data.id]=[];
								window.inputs[data.id].push(data.value);
							}else{
								if(!window.inputs[data.id].includes(data.value)){
									window.inputs[data.id].push(data.value);
								}
							}
                            return that.add.textarea(data, div);
                        },
                        button: function (data = {
                            value: "Click me!",
                            prepend: "",
                            append: "",
                            class: "",
							disabled:false,
                            onclick: function () {
                            }
                        }) {
                            return that.add.button(data, div);
                        },
                        container: function (data = {prepend: "", append: "", class: ""}) {
                            return that.add.container(data, div);
                        }
                    }
                };


                $(div).prepend(data.prepend);
                $(div).append(data.append);
                if (form) {
                    form.append(div);
                } else {
                    that.form.target.append(div);
                }

                return self;
            }

        }
    }
}