'use strict';
Array.prototype.remove=function(value){
	if(this.includes(value)){
		return this.splice(this.indexOf(value),1);
	}
	return false;
}

Array.prototype.insert = function ( index, item ) {
    this.splice( index, 0, item );
};

Array.prototype.each=function(callback){
	if(!(callback instanceof Function))
		throw new TypeError();

	for(let i=0;i<this.length;i++){
		callback.apply(this[i],[i]);
	}
}

String.prototype.toBoolean=function(){
	if(this=="true" || this==true || this=="1" || this==1) return true;
	return false;
}