import "./extension.js";
// import {$} from "./utils.js";

export class Form{
	constructor(data={target:HTMLElement,scenes:[]}){
		const that=this;
		this.data={};
		this.target=data.target;
		this.instance=null;
		this.scenes=data.scenes;
		let queue=[];
		let i=-1;

		that.add=function(){
			queue.push.apply(queue,arguments);
		}

		that.remove=function(){
			for(const value of arguments){
				const i=queue.indexOf(value);
				if(i!=-1){
					queue.splice(i,1);
				}
			}
		}

		that.clear=function(from){
			if(from>0){
				queue.splice(from,queue.length);
				i=from-1;
			}else{
				queue=[];
				i=-1;
			}
		}

		that.queue=function(){
			const result=$(queue).clone()
			return {queue:result,scene:result[i],target:i};
		}
		
		that.prev=function(){
			if(i>0){
				i--;
				that.scene(queue[i])
			}
		}
		that.next=function(){
			if(i<queue.length-1){
				i++;
				that.scene(queue[i])
			}
		}

		this.target.addEventListener("submit",function(event){
			event.preventDefault();
			return false;
		});

		this.scene=function(name){
			let result=false;
			for(const e of this.scenes){
				if(e.name==name){
					$(that.target).html("");
					that.instance=new e(that);
					that.JSON();
					result=true;
					const event=new Event("scene.change");
					event.scene=name;
					that.target.dispatchEvent(event);
					break;
				}
			}
			if(!result) console.error("Can't find scene")
			return result;
		}

		this.validate=function(){
			$(this.target).find(".label-error").removeClass("label-error");
			$(this.target).find(".error").remove();
			const data=this.data;
			let result=true;
			let target=[];
			let l=$(this.target).find("[data-id]");
			for(let i=0;i<l.length;i++){
				let e=l[i];
				if(!e.required || e.disabled) continue;
				const id=$(e).data("id");
				if($(`[data-id="${id}"]`).length>1){
					if(e.type=="checkbox" || e.type=="radio"){
						let checkall=false;
						$(`[data-id="${id}"]`).each(function(){
							if(this.checked){
								checkall=true;
							}
						});
						if(!checkall){
							result=false;
							target.push(e);
						}
					}else{
						if(e.value.replaceAll(" ","")==""){
							result=false;
							target.push(e);
						}
					}
				}else{
					if(e.type=="checkbox"){
						if(!e.checked){
							result=false;
							target.push(e);
						}
					}else{
						if(e.value.replaceAll(" ","")==""){
							result=false;
							target.push(e);
						}
					}
				}
			}

			this.target.checkValidity();

			if(!result && target.length>0){
				console.log(target);
				$(target).each(function(i){
					if($(this).parent().find(".error").length>0){
						if(this.error){
							$(this).parent().find(".error").html(this.error);
						}else{
							$(this).parent().find(".error").html(this.validationMessage);
						}
					}else{
						const div=document.createElement("div");
						div.className="error";
						if(this.error){
							div.innerHTML=this.error;
						}else{
							div.innerHTML=this.validationMessage;
						}
						let id=$(this).data("id");
						$(this).parent().append(div);
					}
					$(this).parent().addClass("label-error");
					if(i==0){
						this.parentNode.scrollIntoView({behavior: "smooth"});
					}
				})
			}
			// console.log(target);

			return result;
		}

		this.JSON=function(){
			const that=this;
			const data=new FormData(this.target);
			$(this.target).find("[data-id]").each(function(i){
				const id=$(this).data("id");
				if($(that.target).find(`[data-id="${id}"]`).length>1){
					if(this.type==="radio"){
						that.data[this.name]=data.get(this.name);
					}else{
						that.data[this.name]=data.getAll(this.name);
					}
				}else{
					if(this.type==="checkbox"){
						if(data.get(this.name)===null){
							that.data[this.name]=false;
						}else{
							if(data.get(this.name).split(" ").join("")!=""){
								that.data[this.name]=data.get(this.name);
							}else{
								that.data[this.name]=true;
							}
						}
					}else if(this.type==="file"){
						const value=data.get(this.name);
						if(value.size>0){
							that.data[this.name]=value;
						}
					}else{
						that.data[this.name]=data.get(this.name);
					}
				}
				if(this.checked){
					$(this).parent().add.class("checked");
				}else{
					$(this).parent().remove.class("checked");
				}
			});
			
			const event=new Event("form.update");
			event.data=that.data;
			window.dispatchEvent(event);
		}

		this.scene(data.scenes[0].name);
	}
}