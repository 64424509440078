/**
 *
 * @param query
 * @returns {NodeList|*}
 */
export const $ = function (query) {
    let all = [query];

    if (typeof query == "string") {
        all = document.querySelectorAll(query);
    }
    if (Array.isArray(query) || query instanceof NodeList || (query.toString && query.toString()=="[object NodeList]")) {
        all = query;
    }

    let self = {};
    for (let i = 0; i < all.length; i++) {
        self[i] = all[i];
    }

	self.version="Allsenses Utils";
	
	if(all[0] && all[0].version==self.version){
		all[0]=all[0][0];
	}

    // START HELPERS
    self.length = all.length;

	self[Symbol.iterator]=function(){
		let index = 0;
		const elements = self;
		return {
			next:function() {
				if (index < elements.length) {
					return { value: elements[index++], done: false };
				}
				return { done: true };
			}
		}
	}

    self.get = function (index = 0) {
        return all[index];
    }

    self.find = function (query) {
        return $(all[0].querySelectorAll(query));
    }

    self.findShallow = function (query) {
        const list = $(all[0].querySelectorAll(query));

        // filtr only with parent of all[0]
        const filtered = [];
        for (let i = 0; i < list.length; i++) {
            if (list[i].parentElement == all[0]) {
                filtered.push(list[i]);
            }
        }

        return $(filtered);
    }

    self.findParent = function (query) {
        let parent = all[0].parentElement;
        if (parent == null) {
            return $();
        }
        if (parent.matches(query)) {
            return parent;
        }
        return $(parent).findParent(query);
    }

    self.findAllParents = function (query) {
        let list = [];
        for (let i = 0; i < all.length; i++) {
            let parent = all[i].parentElement;
            if (parent == null) {
                continue;
            }
            if (parent.matches(query)) {
                list.push(parent);
            }
            let find = $(parent).findAllParents(query);
            if (find.length > 0) {
                list.push(find);
            }
        }
        return $(list);
    }

	self.extends=function(from){
		const to=all[0];
		for(let e in from){
			if(to[e]){
				if(to[e] instanceof Object){
					$(to[e]).extends(from[e]);
				}
			}else{
				to[e]=from[e];
			}
		}
		return to;
	}

	self.clone=function(without=[]){
		const obj=query;
		let res=null;
		if(obj instanceof Object) res={};
		if(Array.isArray(obj)) res=[];
		for(let e in obj){
			if(without.includes(e)) continue;
			if(obj[e] instanceof Object && !(obj[e] instanceof Function)){
				res[e]=$(obj[e]).clone(without);
			}else if(!(obj[e] instanceof Function)){
				res[e]=obj[e];
			}
		}
		return res;
	}

    self.first = function () {
        return $(all[0]);
    }

    self.rect = self.position = function () {
        return all[0].getBoundingClientRect();
    }

	self.parent = function () {
		const parents=[];
		all.forEach(function(e){
			parents.push(e.parentElement);
		})
        return $(parents);
    }

    self.children = function () {
        return $(all[0].children);
    }

    self.one = self.select = function (i) {
        return $(all[i]);
    }

    self.each = function (callback) {
        for (let i = 0; i < all.length; i++) {
            let x = all[i];
            callback.apply(x, [i, x]);
        }
        return self;
    }

    self.offset = function (query) {
        let rect = all[0].getBoundingClientRect();
        return {
            top: rect.top + window.scrollY,
            left: rect.left + window.scrollX
        };
    }

    self.height = function () {
        return all[0].getBoundingClientRect().height;
    }

    self.width = function () {
        return all[0].getBoundingClientRect().width;
    }

    self.id = function () {
        return all[0].id;
    }

    self.eq = function (i) {
        return $(all[i]);
    }

    self.index = function () {
        return Array.from(all[0].parentNode.children).indexOf(all[0]);
    }

    self.css = function (property, value) {
        if (arguments.length === 1) {
            return all[0].style[property];
        }

        all.forEach(function (obj) {
            obj.style.setProperty(property, value);

            // if string and !important
            if (typeof value === "string" && value.includes("!important")) {
                obj.style.setProperty(property, value.replace("!important", ""), "important");
            }
        })
        return self;
    }

    self.removeCss = function (property) {
        all.forEach(function (obj) {
            obj.style.removeProperty(property);
        })
        return self;
    }

    self.outerHeight = function () {
        return all[0].offsetHeight;
    }

    self.scrollTop = function () {
        if (all[0] == window) {
            return window.scrollY;
        }
        return all[0].scrollTop;
    }

    self.scrollLeft = function () {
        if (all[0] == window) {
            return window.scrollX;
        }
        return all[0].scrollLeft;
    }

    self.isInViewport = function (offsetTop = 0, offsetBottom) {
        if (offsetBottom === undefined) offsetBottom = offsetTop;

        const elementTop = self.offset().top - offsetTop;
        const elementBottom = elementTop + self.outerHeight() + offsetBottom;

        const viewportTop = $(window).scrollTop();
        const viewportBottom = viewportTop + window.innerHeight;

        return elementBottom > viewportTop && elementTop < viewportBottom;
    }

    self.is = function (selector) {
        return all[0].matches(selector);
    }

    self.isSelected = function () {
        return all[0].selected;
    }

    self.firstChild = function () {
        return $(all[0].firstChild);
    }

    self.lastChild = function () {
        return $(all[0].lastChild);
    }

    self.next = function () {
        if (all[0].nextElementSibling == null) {
            return $(all[0].parentElement.firstChild);
        }
        return $(all[0].nextElementSibling);
    }

    self.prev = function () {
        if (all[0].previousElementSibling == null) {
            return $(all[0].parentElement.lastChild);
        }
        return $(all[0].previousElementSibling);
    }


    // Functions
    self.scrollTo = function (x = 0, y = 0, duration = 250, callback) {
        let obj = all[0];
        let scrollObject = obj;

        if (obj == window) {
            obj = document.body;
            scrollObject = window;
        }

        if ($(obj).hasClass('scroll-in-progress')) return self;
        $(obj).addClass('scroll-in-progress');

        let start = performance.now();
        let startX = $(obj).scrollLeft();
        let startY = $(obj).scrollTop();

        let diffX = x - startX;
        let diffY = y - startY;

        let step = function (timestamp) {
            let progress = (timestamp - start) / duration;
            if (progress > 1) progress = 1;
            scrollObject.scrollTo(startX + diffX * progress, startY + diffY * progress);
            if (progress < 1) {
                window.requestAnimationFrame(step);
            } else {
                if (callback) callback();
                $(obj).removeClass('scroll-in-progress');
            }
        }

        window.requestAnimationFrame(step);
        return self;
    }


    self.on = function (type, listener, options=false) {
        all.forEach(function (e) {
			if(!e._events) e._events={};
			type.split(" ").forEach(function(type){
				if(!e._events[type]) e._events[type]=[];
				e._events[type].push(listener);
				e.addEventListener(type, listener, options);
			});
        });
        return self;
    }
	
    self.off = function (type, listener) {
		if(arguments.length==2){
			all.forEach(function (e) {
				if(!e._events) e._events={};
				type.split(" ").forEach(function(type){
					e._events[type].splice(e._events[type].indexOf(listener),1);
					e.removeEventListener(type, listener);
				});
			});
		}else{
			all.forEach(function (e) {
				if(!e._events) e._events={};
				type.split(" ").forEach(function(type){
					if(!e._events[type]) return;
					e._events[type].forEach(function(listener,i){
						e.removeEventListener(type, listener);
						e._events[type].splice(i,1);
					});
				});
			});
		}
        return self;
    }

    self.attr = function (attribute, value) {
        if (arguments.length < 2) {
            return all[0].getAttribute(attribute);
        }
        all.forEach(function (e) {
            e.setAttribute(attribute, value);
        });
        return self;
    }

    self.val = function (value) {
        if (arguments.length < 1) {
            return all[0].value;
        }
        all.forEach(function (e) {
            e.value = value;
        });
        return self;
    }

    self.attributes = function (startWith) {
        if (startWith === undefined) return all[0].attributes;

        let attributes = {};
        for (let attr of all[0].attributes) {
            if (attr.name.startsWith(startWith)) {
                attributes[attr.name] = attr.value.toString();
            }
        }
        return attributes;
    }

    self.removeAttr = function (attribute) {
        all.forEach(function (e) {
            e.removeAttribute(attribute);
        });
        return self;
    }

    self.data = function (attribute, value) {
        if (arguments.length < 2) {
            return all[0].getAttribute("data-" + attribute);
        }
        all.forEach(function (e) {
            e.setAttribute("data-" + attribute, value);
        });
        return self;
    }

    self.removeData = function (attribute) {
        all.forEach(function (e) {
            e.removeAttribute("data-" + attribute);
        });
        return self;
    }

    self.trigger = function (eventName) {
        let event = new Event(eventName);
        all.forEach(function (e) {
            e.dispatchEvent(event);
        });
        return self;
    }

    self.form = function () {
        let data = {};
        let l = all[0];
        for (let i = 0; i < l.length; i++) {
            let e = l[i];
            if (e.id) {
                if (e.type === "checkbox") {
                    data[e.id] = e.checked;
                } else {
                    data[e.id] = e.value;
                }
            }
        }
        return data;
    }

    self.html = function (value) {
        if (arguments.length > 0) {
            all.forEach(function (e) {
                e.innerHTML = value;
            });
        } else {
            return all[0].innerHTML;
        }
        return self;
    }

    self.outerHTML = function (value) {
        if (arguments.length > 0) {
            all.forEach(function (e) {
                e.outerHTML = value;
            });
        } else {
            return all[0].outerHTML;
        }
        return self;
    }

    self.text = function (value) {
        if (arguments.length > 0) {
            all.forEach(function (e) {
                e.innerText = value;
            });
        } else {
            return all[0].innerText;
        }
        return self;
    }

    self.append = function (child) {
        if (child.constructor === String) {
            all.forEach(function (e) {
                e.insertAdjacentHTML("beforeend",child);
            });
        } else {
            all.forEach(function (e) {
                return e.append(child);
            });
        }
        return self;
    }

    self.prepend = function (child) {
        if (child.constructor === String) {
            all.forEach(function (e) {
                e.insertAdjacentHTML("afterbegin",child);
            });
        } else {
            all.forEach(function (e) {
                return e.prepend(child);
            });
        }
        return self;
    }

	self.checked=function(checked){
		if(arguments.length>0){
			all.forEach(function(e){
				e.checked=checked;
			});
		}else{
			return all[0].checked;
		}
		return self;
	}

    self.fade = function (duration, easing, delay, callback, opacity) {
        all.forEach(function (e) {
            $(e).css("transition", "opacity " + duration + "ms " + easing + " " + delay + "ms");
            $(e).css("opacity", opacity);
            setTimeout(function () {
                e.style.opacity = opacity;
                if (callback && typeof callback === "function") {
                    callback.bind(e)();
                }
            }, duration + delay);
        });
    }

    self.fadeIn = function (duration, easing, delay, callback) {
        self.fade((duration || 500), (easing || "ease-in-out"), (delay || 0), callback, 1);
        return self;
    }

    self.fadeIn = function (duration, callback) {
        self.fade((duration || 500), "ease-in-out", 0, callback, 1);
        return self;
    }

    self.fadeOut = function (duration, easing, delay, callback) {
        self.fade((duration || 500), (easing || "ease-in-out"), (delay || 0), callback, 0);
        return self;
    }

    self.fadeOut = function (duration, callback) {
        self.fade((duration || 500), "ease-in-out", 0, callback, 0);
        return self;
    }

    self.typingEffect = function (speed = 100, delay = 0, callback) {
        all.forEach(function (e) {
            e = $(e);
            let actWidth = e.css("width");
            let actMaxWidth = e.css("max-width");
            let actHeight = e.css("height");

            let width = e[0].clientWidth;
            let height = e[0].clientHeight;

            let text = e.html()
            e.html("");

            let tags = text.match(/<(?:.|\s)*?>/g);
            if (tags) {
                tags.forEach(function (tag) {
                    text = text.replaceAll(tag, "☚");
                });
            }

            e.css("max-width", width + "px");
            e.css("width", "100%");
            e.css("height", height + "px");

            var elem;
            let i = 0;

            setTimeout(function () {
                setTimeout(function () {
                    let timer = setInterval(function () {
                        if (i < text.length) {
                            if (text[i] === "☚") {
                                let keys = Object.keys(tags);
                                let key = keys[0];
                                let tag = tags[key];
                                delete tags[key];

                                e.html(e.html() + tag);
                                console.log(tag)
                                if (tag.includes("br") || tag.includes("hr") || tag.includes("img") || tag.includes("/")) {
                                    elem = undefined;
                                } else {
                                    elem = e.lastChild();
                                }
                            } else {
                                if (elem) {
                                    elem.html(elem.html() + text[i]);
                                } else {
                                    e.html(e.html() + text[i]);
                                }
                            }

                            i++;
                        } else {
                            e.css("width", actWidth);
                            e.css("max-width", actMaxWidth);
                            e.css("height", actHeight);

                            clearInterval(timer);
                            if (callback) {
                                callback.apply(e)(e);
                            }
                        }
                    }, speed);
                });
            }, delay);
        });
    }


    //Have
    self.has = {};
    self.has.class = self.hasClass = self.class = function (className) {
        let result = false;
        all.forEach(function (e) {
            if (e.classList.contains(className)) {
                result = true;
            }
        })
        return result;
    }


    // Add options
    self.add = {};
    self.add.class = self.addClass = function (className) {
        all.forEach(function (e) {
            e.classList.add(className);
        });
        return self;
    }


    // Remove options
    self.remove = function () {
        all.forEach(function (e) {
            e.remove();
        });
        return self;
    };

    self.remove.class = self.removeClass = function (className) {
        all.forEach(function (e) {
            e.classList.remove(className);
        });
        return self;
    }


    // Toggle options
    self.toggle = {};
    self.toggle.class = self.toggleClass = function (className) {
        all.forEach(function (e) {
            if (e.classList.contains(className)) {
                e.classList.remove(className);
            } else {
                e.classList.add(className);
            }
        });
        return self;
    }


    // Do usuniecia po dodaniu nowego slidera
    self.owlCarousel = function (options) {
        return self;
    }

    // Add all prototype functions
    for (let key in $.prototype) {
        self[key] = $.prototype[key];
    }

    return self;
}
$.ajax = function (obj = {
    method: 'GET',
    url: '',
    data: {},
    dataSeialization: true,
    success: () => { },
    error: () => { },
    complete: () => { },
}) {
    obj = Object.assign({
        method: 'GET',
        url: '',
        data: {},
        dataSeialization: true,
        success: (e) => {
            // console.log(e)
        },
        error: () => { },
        complete: () => { },
    }, obj);

    var xhr = new XMLHttpRequest();

    let data = obj.data;
    if (obj.dataSeialization) {
        data = Object.keys(data).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
        }).join('&');
    }

    if (obj.method === 'GET' && data.length > 0) {
        obj.url += '?' + data;
    }

    xhr.open(obj.method, obj.url, true);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
            if (xhr.status === 200) {
                obj.success(xhr.responseText, xhr.status, xhr);
            } else {
                obj.error(xhr);
            }
            obj.complete(xhr);
        }
    };

    xhr.send(data);
}

$.cookie=function(cookieName,cookieValue,exdays) {
    if(arguments.length==1){
        let name=cookieName+"=";
        let decodedCookie=decodeURIComponent(document.cookie);
        let ca=decodedCookie.split(';');
        for(let i=0;i<ca.length;i++) {
            let c=ca[i];
            while(c.charAt(0)==' ') {
                c=c.substring(1);
            }
            if(c.indexOf(name)==0) {
                return c.substring(name.length,c.length);
            }
        }
        return "";
    }else{
        const d=new Date();
        d.setTime(d.getTime()+(exdays*24*60*60*1000));
        let expires="expires="+d.toUTCString();
        document.cookie=cookieName+"="+cookieValue+";"+expires+";path=/";
    }

}

$.fn = $.prototype;

window.$ = $;
