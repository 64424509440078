export const de = {
    "Pan": "Herr",
    "Pani": "Frau",
    "Imię i nazwisko": "Name, Vorname",
    "Imię": "Dein Name",
    "Kod pocztowy, miejscowość": "PLZ und Ort",
    "Telefon": "Deine Telefonnummer",
    "E-mail": "Dein Email",
    "Konstrukcja schodów": "Treppenkonstruktion",
    "Dane kontaktowe": "Kontaktdaten",

    "Otwór w suficie": "Treppenloch",
    "Wymiary otworu w suficie i schodów": "Größe",
    "Kierunek schodów": "Treppenrichtung",
    "Konstrukcja": "Treppenkonstruktion",
    "Schody policzkowe": "Wangentreppe",
    "Schody policzkowe stalowe": "Stahlwangentreppe",
    "Schody nakładane": "Aufgesattelte",
    "Schody bolcowe": "Bolzentreppe",
    "Schody dywanowe": "Faltwerktreppe",
    "Schody z wangą centralną": "Zentralwange",
    "Schody na beton": "Stufen auf Beton",
    "Schody dywanowe na beton": "Treppe in Faltwerkoptik",
    "Schody kacze": "Sambatreppe",
    "Schody na beton (Optycznie schody dywanowe)": "Stufen auf Beton (Treppe in Faltwerkoptik)",
    "Schody nakładane (Optycznie schody dywanowe)": "Aufgesattelte (Treppe in Faltwerkoptik)",
    "Schody wspornikowe": "Kragarmtreppe",
    "Podstopnie": "Setzstufen",
    "Bez podstopni": "Ohne Setzstufe",
    "Pół podstopnie": "Mit Kinderschutzleisten",
    "Pełne podstopnie": "Mit Setzstufen",
    "Stopień blokowy": "Mit Blockstufe",
    "Bez stopnia blokowego": "Ohne Blockstufe",
    "Stopień blokowy wybrzuszony": "Eintritt ausgebaucht",
    "Balustrada": "Geländerart",
    "Bez balustrady": "Ohne Geländer",
    "Tralka": "Geländerstäbe",
    "Wstęgi": "Gerade oder gebogene Holzbänder",
    "Reling": "Reling",
    "Szkło": "Glass",
    "Długość balustrady": "Brüstungsgeländer",
    "Poręcz": "Handläufe",
    "Materiały i wykończenie": "Holzart und Oberfläche",
    "Rodzaj drewna": "Holzart",

    "Dąb": "Eiche",
    "Dąb rustykalny z sękami": "Eiche Rustikal mit Ästen",
    "Buk": "Buche",
    "Merabu": "Merabu",
    "Orzech Amerykański": "Amerikanischer Nussbaum",
    "Sosna": "Kiefer",
    "Jesion": "Esche",
    "Wykończenie powierzchni": "Oberfläche",
    "Podsumowanie": "Zusammenfassung",
    "Załączniki": "Datei anhängen",
    "Kliknij lub upuść załączniki": "Klicken Sie auf Anhänge oder legen Sie sie ab",
    "Uwagi": "Kommentar",

    "Długość otworu": "Treppenloch länge",
    "Szerokość otworu": "Treppenloch breite",
    "Wysokość schodów": "Treppenhöhe",
    "Szerokość schodów": "Treppenbreite",
    "W lewo": "Links",
    "W prawo": "Recht",
    "Długość balustrady w metrach": "Brüstungsgeländer länge in Metern",
    "Bez wykończenia": "Ohne Ausfertigung",
    "Lakierowane": "Lackiert",
    "Bejcowane": "Gebeizt",
    "Malowane na biało": "Weiß gestrichen",
    "Olejowanie": "Geölt",
    "Inne": "andere",
    "Szkło 1": "Glas 1",
    "Szkło 2": "Glas 2",
    "Szkło 3": "Glas 3",
    "Wyślij formularz": "Jetzt kostenloses Vorschlag anfordern",
    "Zamów kontakt": "Kontakt anfordern",
    "Zamów kontakt telefoniczny": "Rückruf anfordern",
    "Land": "Land/Bundesland/Region",

    "<div class=\"cell small-12 text-center h4\"><hr>Tralka</div>": "<div class=\"cell small-12 text-center h4\"><hr>Baluster</div>",

    "Dalej": "Nächste",
    "Wstecz": "Zurück",
    "Przejdź do konfiguracji schodów": "Zur Treppenkonfiguration",

    "tralka-standard 4 x 2 cm": "Standard Geländerstäbe 4 x 2 cm",
    "tralka-stal nierdzewna": "Edelstahl Geländerstäbe 1",
    "tralka-1": "Baluster Muster 1",
    "tralka-2": "Baluster Muster 2",
    "tralka-3": "Baluster Muster 3",
    "tralka-4": "Baluster Muster 4",
    "tralka-5": "Baluster Muster 5",
    "tralka-6": "Baluster Muster 6",
    "tralka-7": "Baluster Muster 7",
    "tralka-8": "Baluster Muster 8",
    "tralka-9": "Baluster Muster 9",
    "tralka-10": "Baluster Muster 10",
    "tralka-11": "Baluster Muster 11",
    "tralka-12": "Baluster Muster 12",
    "tralka-13": "Baluster Muster 13",
    "tralka-14": "Baluster Muster 14",
    "tralka-15": "Baluster Muster 15",
    "tralka-16": "Baluster Muster 16",
    "tralka-17": "Baluster Muster 17",

    'l-1': 'geraden Treppe',
    'l-2': '1/4 links gewendelten Podesttreppe',
    'l-3': '1/2 links gewendelten Podesttreppe',
    'l-4': '3/2 links gewendelten Treppe',
    'l-5': '1/4 links gewendelten Treppe',
    'l-6': '1/2 links gewendelten Treppe',
    'l-7': '3/4 links gewendelten Podesttreppe',
    'r-1': 'geraden Treppe',
    'r-2': '1/4 rechts gewendelten Podesttreppe',
    'r-3': '1/2 rechts gewendelten Podesttreppe',
    'r-4': '3/4 rechts gewendelten Treppe',
    'r-5': '1/4 rechts gewendelten Treppe',
    'r-6': '1/2 rechts gewendelten Treppe',
    'r-7': '3/4 rechts gewendelten Podesttreppe',

    "#eee": "Weiß",
    "#111": "Schwarz",
    "#323c4b": "Anthrazit",
    "Naturalny": "Naturholzfarbe",
    "Czarny": "Schwarz",
    "Biały": "Weiß",
    "Antracyt": "Anthrazit",
    "Kolor słupków": "Farbe der Baluster",
    "Kolor słupków głównych": "Farbe des Geländerpfostens",
    "Kolor policzków": "Farbe der Wangen",
    "Kolor poręczy": "Farbe des Handlaufs",
    "Kolor stopni": "Farbe der Stufen",
    "Kolor podstopni": "Farbe der Setzstufen",

    "construction": "Treppenkonstruktion",
    "dir_type": "Treppenart",
    "type": "Treppenart",
    "risers": "Setzstufen",
    "block": "Blockstufe",
    "balustrade": "Geländerart",
    "wood_type": "Holzart",
    "handrail": "Handlauf",
    "baluster": "Geländerstäbe",
    "cheeks_color": "Farbe der Wangen",
    "bars_color": "Farbe der Baluster",
    "mainbars_color": "Farbe des Geländerpfostens",
    "handrail_color": "Farbe des Handlaufs",
    "steps_color": "Farbe der Stufen",
    "risers_color": "Farbe der Setzstufen",
    "surface_finish": "Oberfläche",

    "porecz-1": "Handlauf Muster 1",
    "porecz-2": "Handlauf Muster 2",
    "porecz-3": "Handlauf Muster 3",
    "porecz-4": "Handlauf Muster 4",
    "porecz-5": "Handlauf Muster 5"

}