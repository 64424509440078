import {Modal} from './utils/modal.js';
import { Tag } from './utils/tag.js';
import { translate } from './utils/translate.js';

window.cookiesTypes = {
    functionalCookies: {
        name: translate("Functional cookies"),
        description: translate("Cookies that enable basic website functions such as navigation and access to secure areas of the website. Without these cookies, the website cannot function properly."),
        required: true,
    },
    analyticalCookies: {
        name: translate("Analytical cookies"),
        description: translate("Cookies that allow us to analyze how users use the site so that we can measure and improve the performance of the site."),
    },
    marketingCookies: {
        name: translate("Marketing cookies"),
        description: translate("Cookies that enable the delivery of advertising content to users that is more tailored to their interests. Without these cookies, online advertising will not be as relevant to you."),
    },
    personalizationCookies: {
        name: translate("Preference cookies"),
        description: translate("Preferences cookie description."),
    },
};

const initCookie = function() {
    let cookieModal = new Modal({disableClose: true, stickBottom: true, classList: ['p-6', 'p-8-md']});

    cookieModal.append(new Tag({
        tag: "h5",
        classList: ["cookie-title", "font-weight-semibold", "mb-4"],
        text: translate("This website uses cookies"),
    }))

	const tabs=new Tag({
		tag:"div",
		classList:["cookie-tabs"],
		html:/*html*/`
		<a class="tab-button">Einverständnis</a>
		<a class="tab-button">Details</a>
		<div class="tab"></div>
		<div class="tab"></div>
		`,
	});
	cookieModal.append(tabs);

	$(tabs).find(".tab").addClass("hide");
	$(tabs).find(".tab").one(0).removeClass("hide");
	$(tabs).find(".tab-button").one(0).addClass("active");

	$(tabs).find("a").each(function(i){
		$(this).on("click",function(){
			$(tabs).find(".tab").addClass("hide");
			$(tabs).find(".tab").one(i).removeClass("hide");

			$(tabs).find(".tab-button").removeClass("active");
			$(tabs).find(".tab-button").one(i).addClass("active");
		});
	});

	$(tabs).find(".tab").one(0).html(`
	<span class="small mt-1 display-inline-block">Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren, Funktionen für soziale Medien bereitzustellen und unseren Traffic zu analysieren. Informationen über deine Nutzung unserer Website teilen wir mit unseren Partnern für soziale Medien, Werbung und Analyse. Diese Partner können diese Daten mit weiteren Informationen kombinieren, die du ihnen bereitgestellt hast oder die sie durch deine Nutzung ihrer Dienste gesammelt haben.</span>
	`);

    for (let key in cookiesTypes){
        let cookie = cookiesTypes[key];

        let label = new Tag({
            tag: "label",
            classList: ["checkbox-label"],
        });

        let input = new Tag({
            tag: "input",
            type: "checkbox",
            name: key,
            classList: ["checkbox"],
        });
        if (cookie.required) {
            input.checked = true;
            input.disabled = true;
        }

        let indicator = new Tag({
            tag: "span",
            classList: ["indicator"],
        });

        let span = new Tag({
            tag: "span",
            classList: ["label"],
            html: `<span class="font-weight-semibold">${cookie.name}</span><br><span class="small mt-1 display-inline-block">${cookie.description}</span>`,
        });

        label.append(input);
        label.append(indicator);
        label.append(span);
        $(tabs).find(".tab").one(1).append(label);
    }

    let buttonGrid = new Tag({
        tag: "div",
        classList: ["grid-x", "container", "mt-4"],
        style: {
            "--gap": "3px",
        }
    });

    let rejectButton = new Tag({
        tag: "button",
        classList: ["cell", "md-4", "sm-12", "button",'small', "cookie-button", "outline", "gray"],
        text: translate("Reject all"),
    });

    rejectButton.on("click", function() {

        let cookie = {};
        for (let key in cookiesTypes) {
            cookie[key] = false;
        }
        cookie.functionalCookies = true;

        localStorage.setItem("cookie", JSON.stringify(cookie));
        cookieModal.hide();
        cookieModal.remove();

        $(window).trigger("cookie.accepted", {cookie: cookie});
    });

    let selectedButton = new Tag({
        tag: "button",
        classList: ["cell", "md-4", "sm-12", "button", 'small', "cookie-button", "outline", "gray"],
        text: translate("Save selected"),
    });

    selectedButton.on("click", function() {
        let cookie = {};
        for (let key in cookiesTypes) {
            cookie[key] = document.querySelector(`input[name="${key}"]`).checked;
        }

        localStorage.setItem("cookie", JSON.stringify(cookie));
        cookieModal.hide();
        cookieModal.remove();

        $(window).trigger("cookie.accepted", {cookie: cookie});
    });



    let allButton = new Tag({
        tag: "button",
        classList: ["cell", "md-4", "sm-12", "button",'small', "primary", "cookie-button"],
        text: translate("Accept all"),
    });

    allButton.on("click", function() {
        let cookie = {};
        for (let key in cookiesTypes) {
            cookie[key] = true;
        }

        localStorage.setItem("cookie", JSON.stringify(cookie));
        cookieModal.hide();
        cookieModal.remove();

        $(window).trigger("cookie.accepted", {cookie: cookie});
    });

    buttonGrid.append(rejectButton);
    buttonGrid.append(selectedButton);
    buttonGrid.append(allButton);
    cookieModal.append(buttonGrid);

    cookieModal.show();

}

window.addEventListener("load", function() {
    let cookie = JSON.parse(localStorage.getItem("cookie"));

    if (!cookie) {
        window.addEventListener('load', function() {
            setTimeout(initCookie(), 3000);
        });
        return;
    }

    for (let key in cookiesTypes) {
        if (cookie[key] == undefined) {
            window.addEventListener('load', function() {
                setTimeout(initCookie(), 3000);
            });
            return;
        }
    }

    $(window).trigger("cookie.accepted", {cookie: cookie});
},{once:true});

window.checkCookie = function(name) {
    if (name) {
        let cookie = JSON.parse(localStorage.getItem("cookie"));
        if (cookie) {
            return cookie[name];
        }
    }

    return JSON.parse(localStorage.getItem("cookie"));
}