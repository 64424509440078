import {Form} from '../gazda-form/js/advanced-forms/form.js';
import {Start, Short} from '../gazda-form/js/contactform.js';
import {Modal} from './utils/modal.js';
import {Tag} from './utils/tag.js';
import {translate} from './utils/translate.js';

// Funkcja, która będzie wywoływana po określonym czasie
let modal = null;

function showModal(type = "exit") {

    if (sessionStorage.getItem("modalClosed") === "true") {
        return;
    }

    if (modal) {
        modal.show();
        return;
    }
    modal = new Modal({
        classList: ["modal-wrapper"]
    });
    modal.html(`<form class="footer-contact mouseout-contact" action=""></form>`);
    modal.show();
    $(modal.modal).css("max-width", "750px");
    $(modal.modal).css("width", "100%");

    $(".modal-background").addClass("active");
    $(".modal-background")[0].addEventListener("click", closeModal, {once: true});

    let scene = [
        Start
    ]
    let AB = Math.round(Math.random());
    if (AB == 1) {
        scene = [
            Short
        ]
    }

    const form = new Form({
        target: $(".mouseout-contact")[0],
        scenes: scene
    });
    form.modal = modal;

    if (AB == 0) {

        let desc = $(form.target).find(".desc");

        let beforeTitle = '';
        if (type === "exit") {
            beforeTitle = 'Verlassen Sie uns noch nicht!';
        }

        $(form.target).find(".title").html(`${beforeTitle} Wir haben ein spezielles Angebot für Sie!`);

        desc.html(`<strong class="text-primary">Kostenloser 3D-Entwurf Ihrer Treppe im Wert von 370 Euro!</strong> Gestalten Sie Ihre Traumtreppe mit unserem <a class="underline text-primary"
	href="/kostenlose-angebotserstellung">Konfigurator</a>. Unser Berater hilft bei Fragen. Hinterlassen Sie Ihre Daten und erhalten Sie ein unverbindliches, maßgeschneidertes Angebot. Nutzen Sie diese Gelegenheit jetzt!`)


        desc.addClass("shrinked-text").addClass('mb-3').addClass('md-mb-6');
        desc[0].insertAdjacentHTML("afterend", '<span class="text-accent mb-5 mehr-button">Mehr &gt;</span>');


        $(".shrinked-text").each(function () {
            $(this).css("--height", this.scrollHeight + "px");
        });

        $(window).on("resize", function () {
            $(".shrinked-text").each(function () {
                $(this).css("--height", this.scrollHeight + "px");
            });
        });

        $(".mehr-button").on("click", function () {
            if ($(this).hasClass("active")) {
                $(this).removeClass("active");
                $(this).parent().find(".shrinked-text").removeClass("unshrink");
                $(this).text("Mehr >");
            } else {
                $(this).addClass("active");
                $(this).parent().find(".shrinked-text").addClass("unshrink");
                $(this).text("Weniger <");
            }
        });

    }
    $(form.target).parent().prepend('<div class="text-right"><span class="modal-close" style="font-size:40px;">+</span></div>');

    $(form.target).parent().find(".modal-close").on("click", function () {
        modal.hide();
        closeModal();
    });

}

function checkUrlExclusion() {
    const url = window.location.pathname;
    return url !== "/kostenlose-angebotserstellung" && url !== "/kontakt" && url !== "/";
}

//if mobile time, if desktop mouseout
const mouseEvent = function (e) {
    if (e.clientY < 10 && checkUrlExclusion() && sessionStorage.getItem("modalClosed") !== "true") {
        document.removeEventListener('mouseout', mouseEvent);
        showModal();
    }
}


document.addEventListener('mouseout', mouseEvent);
window.addEventListener("load", initPageVisit);


function initPageVisit() {


    if (sessionStorage.getItem("modalClosed") === "true") {
        return;
    }

    let pageVisits = parseInt(sessionStorage.getItem("pageVisits") || "0") + 1;
    sessionStorage.setItem("pageVisits", pageVisits);

    let delayTime = 15000
    if (checkUrlExclusion() && localStorage.getItem("cookie") !== null) {
        setTimeout(function () {
            showModal('time');
        }, delayTime);
    } else {
        window.addEventListener("cookie.accepted", function (event) {
            if (checkUrlExclusion()) {
                setTimeout(function () {
                    showModal('time');
                }, delayTime);
            }
        });
    }

    // if (pageVisits > 2) {
    //     let delayTime = 45000;
    //     if (checkUrlExclusion()) {
    //         setTimeout(function () {
    //             showModal('time');
    //         }, delayTime);
    //     }
    // } else if (pageVisits > 3 && pageVisits < 5) {
    //     let delayTime = 25000
    //     if (checkUrlExclusion()) {
    //         setTimeout(function () {
    //             showModal('time');
    //         }, delayTime);
    //     }
    // } else if (pageVisits >= 5 && pageVisits < 7) {
    //     let delayTime = 15000
    //
    //     if (checkUrlExclusion()) {
    //         setTimeout(function () {
    //             showModal('time');
    //         }, delayTime);
    //     }
    // } else {
    //     let delayTime = 5000
    //     if (checkUrlExclusion()) {
    //         setTimeout(function () {
    //             showModal('time');
    //         }, delayTime);
    //     }
    // }
}

function closeModal() {
    sessionStorage.setItem("modalClosed", "true");
}