const defaultLanguage = document.documentElement.lang;


// Read lang file
const { translates } = await import(`/scripts/js/langs/${defaultLanguage}.js`).then(module => module).catch(function (err) {}) || {};

// Translate
export function translate(key) {
    let result=key;
    if(translates && translates[key]){
        result=translates[key];
    }else{
        console.warn(`[${defaultLanguage}] Missing translation for "${key}"`);
    }
    return result;
}