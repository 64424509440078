import {Form} from "./advanced-forms/form.js";
import {Scene} from "./advanced-forms/scene.js";
import {Modal} from "../../js/utils/modal.js";
import {Tag} from "../../js/utils/tag.js";

export class Start extends Scene {
    constructor(form = new Form) {
        super(form);
        const that = this;

        if(form.modal) {
            var close = this.add.container({
                append:/*html*/`
			<span class="modal-close">+</span>`,
                class: "text-right"
            });

            close.target.addEventListener("click", function () {
                form.modal.hide()
            });
        }

        var content = this.add.container({
            append:/*html*/`
			<div class="h3 title">Ihr Weg zur Traumtreppe beginnt hier</div>
			<p class="text-left desc">Hinterlassen Sie Ihre Kontaktdaten und erhalten Sie eine individuelle Beratung für Ihre perfekte Holztreppe.</p>`
        });

        content.add.input({
            class: "label-text",
            type: "text",
            id: "name",
            prepend:/*html*/`
				<div>
					Name
				</div>
			`,
            required: true,
            value: "",
            oninput: check
        });

        content.add.container({
            prepend: "Wähle Deine bevorzugte Kontaktform aus:"
        });

        content.add.input({
            class: "hide",
            type: "hidden",
            id: "formType",
            value: "kontakt",
        });

        content.add.input({
            class: "hide",
            type: "hidden",
            id: "technology",
            value: localStorage.getItem("initialData") || "{}",
        });


        var radio = content.add.container({
            class: "grid-x selects",
        });

        radio.add.input({
            class: "label-radio cell auto",
            type: "radio",
            id: "type",
            value: "phone",
            prepend:/*html*/`
				Telefon
			`,
            onchange: typeChange
        });

        radio.add.input({
            class: "label-radio cell auto",
            type: "radio",
            id: "type",
            value: "email",
            prepend:/*html*/`
				Email
			`,
            checked: true,
            onchange: typeChange
        });


        function typeChange() {
            $(phone.target).find("input").removeAttr("required");
            $(email.target).find("input").removeAttr("required");

            $(email.target).addClass("hide");
            $(phone.target).addClass("hide");
            if (this.value == "phone") {
                $(phone.target).find(`[name="phone"]`).attr("required", "true");
                $(phone.target).removeClass("hide");
            }
            if (this.value == "email") {
                $(email.target).find(`[name="email"]`).attr("required", "true");
                $(email.target).removeClass("hide");
            }
            check();
        }

        const email = content.add.container({
            class: ""
        });

        email.add.input({
            class: "label-text",
            type: "email",
            id: "email",
            placeholder: "z.B. exempel@gmail.com",
            prepend:/*html*/`
				<div>
					Dein E-mail
				</div>
			`,
            value: "",
            required: true,
            oninput: check
        });

        // const question=email.add.select({
        // 	id:"question",
        // 	checked:"",
        // 	values:{
        // 		"":"",
        // 		"Are you working?":"",
        // 	},
        // 	onchange:function(){
        // 		if(question.select.value==""){
        // 			$(message.label).removeClass("hide");
        // 			return
        // 		}
        // 		$(message.label).addClass("hide");
        // 	}
        // });

        const message = email.add.textarea({
            prepend: "Nachricht",
            id: "message",
        })

        email.add.button({
            value: "Kontakt anfordern",
            class: "button disabled red",
            disabled: true
        });

        const phone = content.add.container({
            class: "hide"
        });

        phone.add.input({
            class: "label-text",
            type: "tel",
            id: "phone",
            placeholder: "+49 ___ _______",
            prepend:/*html*/`
				<div>
				Deine Telefonnummer
				</div>
			`,
            oninput: check,
            value: "",
        });

        phone.add.container({
            prepend:/*html*/`
				Wenn du möchtest, wähle einen Tag aus
			`
        })

        const calendar = phone.add.container({
            class: "grid-x selects",
        });
        let date = new Date();

        const days = [
            "",
            "Mon",
            "Die",
            "Mit",
            "Don",
            "Fre",
            "Sam",
            "Son",
        ];
        for (let i = 0; i <= 6; i++) {
            const day = date.getDay() || 7;
            const {input, label} = calendar.add.input({
                id: "calendar",
                type: "checkbox",
                class: "label-radio cell auto",
                required: false,
                prepend: `
				${days[day]}
				<br>
				${date.getDate()}
				`,
                value: `${date.toLocaleDateString()}`
            });
            if (day == 6 || day == 7) {
                input.disabled = true;
                $(label).addClass("disabled");
            }
            date.setDate(date.getDate() + 1);
        }

        phone.add.container({
            prepend:/*html*/`
				und wähle einen Uhrzeit aus
			`
        });

        const hours = phone.add.container({
            class: "grid-x selects",
        });
        var values = [
            "8:00 - 12:00",
            "12:00 - 14:00",
            "14:00 - 16:00",
        ];
        for (let i = 0; i < 3; i++) {
            hours.add.input({
                id: "hours",
                type: "checkbox",
                required: false,
                class: "label-radio cell auto",
                prepend:/*html*/`
					${values[i]}
				`,
                value: values[i]
            });
        }

        // const that = this;

        function check() {
            const l = $(that.form.target).find("[required]");
            for (let i = 0; i < l.length; i++) {
                const e = l[i];
                console.log(e.value);
                if (e.value.replace(/ /g, "") == "") {
                    $(that.form.target).find("button").parent().addClass("disabled");
                    $(that.form.target).find("button").attr("disabled", true);
                    return;
                }

                console.log($(that.form.target).find("button").parent());

                $(that.form.target).find("button").parent().removeClass("disabled");
                $(that.form.target).find("button").removeAttr("disabled");
            }
        }

        phone.add.button({
            value: "Rückruf anfordern",
            class: "button disabled red",
            disabled: true
        });

        $(this.form.target).on("submit", getToken);

        function getToken(event) {

            event.preventDefault();
            grecaptcha.ready(function () {
                grecaptcha.execute('6Lel5bokAAAAAPmHbu-fVMND5c_bQ9kUdlJCxHxs', {action: 'valuations'}).then(function (token) {

                    var hidden = document.createElement('input');
                    hidden.type = 'hidden';
                    hidden.name = 'recaptcha_token';
                    hidden.id = 'recaptcha_token';
                    hidden.value = token;
                    var form = event.target;
                    form.appendChild(hidden);

                    submitForm();
                });
            });
        }

        function submitForm() {

            form.data.recaptcha_token = document.getElementById('recaptcha_token').value;


            const formData = new FormData();
            for (const key in form.data) {
                formData.append(key, form.data[key]);
            }

            var xhttp = new XMLHttpRequest();
            xhttp.open('POST', '/valuations', true);
            xhttp.send(formData);
            xhttp.onreadystatechange = function () {
                // console.log(xhttp.responseText);
                if (xhttp.readyState == 4 && xhttp.status == 200) {
                    if (xhttp.responseText) {

                        // konwersja kontakt
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({'event': 'contact'});
                        console.log("contact event");

                        location.href = `https://www.holztreppengazdzicki.de/kostenlose-angebotserstellung/sukces/id/${xhttp.responseText}`;

                        // Modal(`Nachricht wurde gesendet <a class="fal fa-xmark close"></a>`);
                        // $('.contact-help').removeClass('active');

                    }
                    document.getElementById('recaptcha_token').remove();
                    // document.getElementById("ack-message").innerHTML = xhttp.responseText;
                }
            }

        }
    }
}

export class Short extends Scene {
	constructor(form = new Form) {
		super(form);

        if(form.modal) {
            var close = this.add.container({
                append:/*html*/`
			<span class="modal-close">+</span>`,
                class: "text-right"
            });

            close.target.addEventListener("click", function () {
                form.modal.hide()
            });
        }

		var content = this.add.container({
			append:/*html*/`
			<div class="h3 title">Brauchen Sie Hilfe?</div>
			<p class="text-left desc mb-5">Wenn Sie nicht finden konnten, was Sie suchen, oder mehr Informationen benötigen, hinterlassen Sie bitte Ihre Telefonnummer. Wir rufen innerhalb <strong>von 24 Stunden zurück</strong>.</p>`
		});

		const phone = content.add.container();

		phone.add.input({
			class: "label-text",
			type: "tel",
			id: "phone",
			placeholder: "+49 ___ _______",
			prepend:/*html*/`
				<div>
				Deine Telefonnummer
				</div>
			`,
			oninput: check,
			value: "",
		});

		const that = this;

		function check() {
			const l = $(that.form.target).find("[required]");
			for (let i = 0; i < l.length; i++) {
				const e = l[i];
				console.log(e.value);
				if (e.value.replace(/ /g, "") == "") {
					$(that.form.target).find("button").parent().addClass("disabled");
					$(that.form.target).find("button").attr("disabled", true);
					return;
				}

				console.log($(that.form.target).find("button").parent());

				$(that.form.target).find("button").parent().removeClass("disabled");
				$(that.form.target).find("button").removeAttr("disabled");
			}
		}

		phone.add.button({
			value: "Rückruf anfordern",
			class: "button disabled red",
			disabled: true
		});

		$(this.form.target).on("submit", getToken);

		function getToken(event) {

			event.preventDefault();
			grecaptcha.ready(function () {
				grecaptcha.execute('6Lel5bokAAAAAPmHbu-fVMND5c_bQ9kUdlJCxHxs', {action: 'valuations'}).then(function (token) {

					var hidden = document.createElement('input');
					hidden.type = 'hidden';
					hidden.name = 'recaptcha_token';
					hidden.id = 'recaptcha_token';
					hidden.value = token;
					var form = event.target;
					form.appendChild(hidden);

					submitForm();
				});
			});
		}


        function submitForm() {

            form.data.recaptcha_token = document.getElementById('recaptcha_token').value;


            const formData = new FormData();
            for (const key in form.data) {
                formData.append(key, form.data[key]);
            }

            var xhttp = new XMLHttpRequest();
            xhttp.open('POST', '/valuations', true);
            xhttp.send(formData);
            xhttp.onreadystatechange = function () {
                // console.log(xhttp.responseText);
                if (xhttp.readyState == 4 && xhttp.status == 200) {
                    if (xhttp.responseText) {

                        // konwersja kontakt
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({'event': 'contact'});
                        console.log("contact event");

                        location.href = `https://www.holztreppengazdzicki.de/kostenlose-angebotserstellung/sukces/id/${xhttp.responseText}`;

                        // Modal(`Nachricht wurde gesendet <a class="fal fa-xmark close"></a>`);
                        // $('.contact-help').removeClass('active');

                    }
                    document.getElementById('recaptcha_token').remove();
                    // document.getElementById("ack-message").innerHTML = xhttp.responseText;
                }
            }

        }

	}
}

//
// function getToken(event) {
// 	// console.log(event);
// 	event.preventDefault();
//
// 		grecaptcha.ready(function() {
// 		grecaptcha.execute('6Lel5bokAAAAAPmHbu-fVMND5c_bQ9kUdlJCxHxs', { action: 'sendform' }).then(function(token) {
//
// 			var hidden = document.createElement('input');
// 			hidden.type = 'hidden';
// 			hidden.name = 'recaptcha_token';
// 			hidden.id = 'recaptcha_token';
// 			hidden.value = token;
//
// 			var form = document.getElementById("contact-help");
// 			form.appendChild(hidden);
//
// 			submitForm();
// 		});
// 	});
// }

// function submitForm() {
// 	const form = document.getElementById('contact-help');
// 	const formData = new FormData(form);
// 	var xhttp = new XMLHttpRequest();
// 	xhttp.open('POST', '/sendform', true);
// 	xhttp.send(formData);
// 	xhttp.onreadystatechange = function() {
// 		// console.log(xhttp.responseText);
// 		if (xhttp.readyState == 4 && xhttp.status == 200) {
// 			if(xhttp.responseText=='true') {
// 				Modal(`Nachricht wurde gesendet <a class="fal fa-xmark close"></a>`);
// 				$('.contact-help').removeClass('active');
// 				document.getElementById('recaptcha_token').remove();
// 				gtag_report_conversion(window.location.pathname,'OdQKCOuJsLcYEJeAqvIp');
// 			}
// 			// document.getElementById("ack-message").innerHTML = xhttp.responseText;
// 		}
// 	}
// }

        if ($("#contact-help").length > 0 && location.pathname != "/kostenlose-angebotserstellung") {
            const form = new Form({
                target: $("#contact-help")[0],
                scenes: [
                    Start
                ]
            });
            window.Help = form;
        }

        if ($(".footer-contact").length > 0) {
            const form = new Form({
                target: $(".footer-contact")[0],
                scenes: [
                    Start
                ]
            });
            window.Form = form;
        }

        const a = new Tag({
            tag: "a",
            classList: ["express-contact-button"],
            html:/*html*/`
	<img class="icon icon-small text-primary-dark icon-type-fontawesome-light" data-src="/images/icons/fontawesome-light/messages.svg">`
        });
        document.body.append(a);

        let modal = null;
        $(a).on("click", function () {
            if (modal) {
                modal.show();
                return;
            }
            modal = new Modal({
                onHide: function () {
                    modal.remove();
                    $(".modal-background").removeClass("active")
                },
                classList: ["modal-wrapper"]
            });
            modal.html(`<form class="footer-contact express-contact" action=""></form>`);
            modal.show();

            $(".modal-background").addClass("active");

            const form = new Form({
                target: $(".express-contact")[0],
                scenes: [
                    Start
                ]
            });
        })