// import {animate} from "./animate.js";

import { VcAnimation } from './tools/vc-animation.js';
import { Tag } from './utils/tag.js';

document.querySelectorAll("dotlottie-player").forEach(function(player) {
    player.addEventListener("ready", function(e) {

        // If player has class include "text-"
        if (player.classList.toString().includes("text-")) {
            let frameEvent = player.addEventListener("frame", function(e) {
                player.shadowRoot.querySelectorAll("path").forEach(function(path) {
                    if (path.getAttribute("fill")) {
                        path.style.fill = "currentColor";
                    }

                    if (path.getAttribute("stroke")) {
                        path.style.stroke = "currentColor";
                    }
                });
            });

            player.addEventListener("loop", function(e) {
                player.removeEventListener("frame", frameEvent);
            });
        }

    });
});

$(window).on("load", function() {
    $("#nav-icon").on("click", function() {
        $("#main-menu").toggleClass("active");
        $("#nav-icon").toggleClass("active");
    });
},{once:true});

document.querySelectorAll("img[data-src]").forEach((e) => {
    // Add parent class skeleton
    e.parentElement.classList.add("skeleton");
});


$(window).on("load", function() {
	loadAllMain();

    let mouseX = 0;
    let mouseY = 0;

    let ballX = 0;
    let ballY = 0;

    let speed = 0.2;  //how fast ball catches up to mouse pointer;

    // Create dot cursor
    let dotCursor = document.createElement("div");
    dotCursor.classList.add("dot-cursor-container");
    document.body.appendChild(dotCursor);

    // Move dot cursor
    document.addEventListener("mousemove", function(e) {
        mouseX = e.clientX;
        mouseY = e.clientY;
    });

    $(".cursor-dot").on("mouseenter", function() {
        dotCursor.classList.add("active");
    });

    $(".cursor-dot").on("mouseleave", function() {
        dotCursor.classList.remove("active");
    });

    $(".cursor-dot picture").on("mouseenter", function() {
        dotCursor.classList.add("picture");
    });

    $(".cursor-dot picture").on("mouseleave", function() {
        dotCursor.classList.remove("picture");
    });

    function animateDot() {
        let distX = mouseX - ballX;
        let distY = mouseY - ballY;
        ballX = ballX + (distX * speed);
        ballY = ballY + (distY * speed);
        dotCursor.style.left = ballX + 'px';
        dotCursor.style.top = ballY + 'px';
        requestAnimationFrame(animateDot)
    }

    animateDot();


    $("#panel-bar-hover").on("mouseenter", function() {
        $(".panel-bar").addClass("active");
    });

    $(".panel-bar").on("mouseleave", function(e) {
        $(".panel-bar").removeClass("active");
    });

    scrollTo(0, window.scrollY);

    $("a[data-smooth-scroll]").on("click", function(e) {
        // Smooth scroll to element
        const href = $(this).attr("href");
        // console.log(href);
		if(!href.startsWith("#")) return;
        const target = $(href);
        
        if (target.length > 0) {
            e.preventDefault();
            $(window).scrollTo(0, target.get(0).getBoundingClientRect().top + window.scrollY - 50, 500);
        }
    });

    $("textarea[value]").each(function() {
        $(this).val($(this).attr("value"));
    });

    // Modal
    $("[data-modal]").on("click", function() {
        const modal = $(`#${$(this).attr("data-modal")}`);
        modal.toggleClass("active");

        if (modal.hasClass("active")) {
            $(".modal-background").addClass("active");
        } else {
            $(".modal-background").removeClass("active");
        }
    });

    $("[data-modal-close]").on("click", function() {
        const modal = $(this).closest(".modal");
        modal.removeClass("active");
        $(".modal-background").removeClass("active");
    });

    const modalBackground = document.createElement("div");
    modalBackground.classList.add("modal-background");
    document.body.appendChild(modalBackground);

    $(modalBackground).on("click", function() {
        $(".modal.active").each(function() {
            if (!$(this).hasClass("disable-close")) {
                $(this).removeClass("active");
            }
        });

        if ($(".modal.active").length == 0) {
            $(this).removeClass("active");
        }
    });


    // Dropdown
    $(".dropdown").each(function() {
        const content = $(this).find(".content");

        $(this).on("click", function() {
            $(this).toggleClass("active");
            content.css("max-height", content.css("max-height") ? null : content.prop("scrollHeight") + "px");
        });

        $(this).on("blur", function() {
            $(this).removeClass("active");
            content.css("max-height", null);
        });

        $(this).on("focus", function() {
            $(this).addClass("active");
            content.css("max-height", content.css("max-height") ? null : content.prop("scrollHeight") + "px");
        });

        $(this).on("mouseenter", function() {
            $(this).addClass("active");
            content.css("max-height", content.css("max-height") ? null : content.prop("scrollHeight") + "px");
        });

        $(this).on("mouseleave", function() {
            $(this).removeClass("active");
            content.css("max-height", null);
        });
    });

    // Accordion
    $(".accordion > .title").on("click", function() {
        $(this).parent().parent().find(".accordion").each(function () {
            $(this).removeClass("pre-active").removeClass("active")
            $(this).find(".item").css("max-height", `0px`);
        })

        $(this).parent().toggleClass("active");
        $(this).parent().removeClass("pre-active");
        const item = $(this).next();
        if ($(this).parent().hasClass("active")) {
            item.css("max-height", (item[0].scrollHeight + 24) + "px");
        } else {
            item.css("max-height", `0px`);
        }
    });



    // Tooltip
    const tooltips = document.createElement("div");
    tooltips.classList.add("tooltip");
    document.body.appendChild(tooltips);

    $("[data-tooltip]").on("mouseenter", function() {
        tooltips.innerHTML = $(this).attr("title");
        tooltips.addClass("active");
        const type = $(this).attr("data-tooltip") || "bottom";
        if (type) {
            let rect = this.getBoundingClientRect();
            let top = rect.top + window.scrollY;
            let left = rect.left + window.scrollX;
            let width = rect.width;
            let height = rect.height;

            let tooltipWidth = tooltips.offsetWidth;
            let tooltipHeight = tooltips.offsetHeight;

            let offset = 10;

            if (type === "top") {
                let x = left + width / 2 - tooltipWidth / 2;
                let y = top - tooltipHeight - offset;

                if (0 > x) x = 0;
                if (0 > y) y = 0;

                tooltips.style.left = x + "px";
                tooltips.style.top = y + "px";
            } else if (type === "bottom") {
                let x = left + width / 2 - tooltipWidth / 2;
                let y = top + height + offset;

                if (0 > x) x = 0;
                if (0 > y) y = 0;

                tooltips.style.left = x + "px";
                tooltips.style.top = y + "px";
            } else if (type === "left") {
                let x = left - tooltipWidth - offset;
                let y = top + height / 2 - tooltipHeight / 2;

                if (0 > x) x = 0;
                if (0 > y) y = 0;

                tooltips.style.left = x + "px";
                tooltips.style.top = y + "px";
            } else if (type === "right") {
                let x = left + width + offset;
                let y = top + height / 2 - tooltipHeight / 2;

                if (0 > x) x = 0;
                if (0 > y) y = 0;

                tooltips.style.left = x + "px";
                tooltips.style.top = y + "px";
            }

            tooltips.classList.add(type);
        }
    });

    $("[data-tooltip]").on("mouseleave", function() {
        tooltips.removeClass("active");
        tooltips.removeClass("top");
        tooltips.removeClass("bottom");
        tooltips.removeClass("left");
        tooltips.removeClass("right");
        tooltips.style = "";
    });

    // Add async scripts
    addScript("https://www.google.com/recaptcha/api.js?render=6Lel5bokAAAAAPmHbu-fVMND5c_bQ9kUdlJCxHxs");

	// Add play modal
	$("a.play-modal").on("click", function(e) {
		e.preventDefault();
		let url = $(this).attr("href") || "";
		let title = $(this).attr("title") || "";
		let modal = new Modal({
			onHide: function() {
				modal.remove();
			}
		})
		if (url.includes("youtu.be")) {
			url = url.replace("youtu.be", "youtube.com/embed");
		} else {
			url = url.replace("watch?v=", "embed/");
		}
		let iframe = new Tag({
			tag: "iframe",
			attr: {
				width: "100%",
				height: "720",
				src: url,
				title: title,
				frameborder: "0",
				allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
				allowfullscreen: true,
				autoplay: true,
			},
		});
		modal.html(`<h2>${title}</h2>`)
		modal.append(iframe);
		modal.get().style.width = "1280px";
		modal.show()
    });
},{once:true});

window.vcanimations = [];
const loadAllMain = window.loadAllMain = function() {

    // Load VcAnimation
    $("[vcanimation]").each(function() {
        if ($(this).attr("vcanimation") == "loaded") return;

        const aniData = $(this).attr("vcanimation");
        const json = JSON.parse(aniData);

        for (const jsonElement of json) {
            const animation = new VcAnimation(this, jsonElement);
            window.vcanimations.push(animation);
        }
        $(this).attr("vcanimation", "loaded");
    });

    $("iframe[data-src]").each(async function() {
        let obj = $(this);
        if (obj.isInViewport(1000)) {
            obj.attr("src", obj.attr("data-src"));
            obj.removeData("src");
        }
    });

    $("img[data-src]").each(async function() {
        let obj = $(this);
        if (obj.isInViewport(1000) || obj.attr("forceload")) {
            if (obj.attr("data-src").endsWith(".svg")) {
                if (obj.attr("data-src-loading") == "true") return;
                obj.attr("data-src-loading", true);

                fetch(obj.attr("data-src")).then(response => response.text()).then((response) => {

                    if (response.includes("<!DOCTYPE html>")) {
                        if (obj.attr("data-src").includes("/icons/file")) {
                            obj.attr("data-src-loading", false);
                            obj.attr("data-src", '/images/icons/files/file.svg');
                            loadAllMain();
                        }
                        return;
                    }

                    // If parent of image is picture
                    let replaceObj = obj;
                    if (obj.parent().is("picture")) {
                        replaceObj = obj.parent();
                    }

                    // Replace outerHTML of image with SVG, and copy all attributes
                    const classes = replaceObj.attr("class");
                    const id = replaceObj.attr("id");

                    // Change response to element
                    const parser = new DOMParser();
                    const svg = parser.parseFromString(response, "text/html").querySelector("svg");

                    // Copy attributes
                    if (classes) svg.setAttribute("class", classes);
                    if (id) svg.setAttribute("id", id);

                    // Replace outerHTML
                    replaceObj.outerHTML(svg.outerHTML);

                }).catch(error => function() {
                    console.log("Error loading SVG", error);
                });

            } else {
                /*                obj.attr("src", obj.data("src"));
                                obj.on("error", function () {
                                    obj.attr("src", "/th/sm/UNKNOWN");
                                });
                                obj.removeData("src");

                                obj.on("load", function () {
                                    let preloader = $(this).findParent(".preloader");
                                    if (preloader && preloader.length > 0 && preloader[0]) {
                                        preloader.removeClass("loading").removeClass("preloader")
                                    }
                                });*/

                const url = obj.data("src");
                obj.removeData("src");

                obj.on("load", function() {
                    obj.parent().removeClass("skeleton");
                });
                obj.attr("src", url);

            }
        }
    });

    $("[data-bg]").each(async function() {
        const obj = $(this);
        const src = obj.attr("data-bg");
        if (obj.isInViewport(1000)) {
            obj.attr("style", `background-image:url(${src})`);
        }
    });

    $("source[data-srcset]").each(function() {
        let obj = $(this);
        if (obj.isInViewport(1000)) {
            obj.attr("srcset", obj.data("srcset"));
            obj.on("error", function() {
                obj.attr("srcset", "/th/sm/UNKNOWN");
            });
            obj.removeData("srcset");
        }
    });

	if($(".calc-sizes").length>0){
		$(".calc-sizes").find(".swiper-slide").each(function() {
			let obj = $(this);
			let slideHeight = $(this).height().toFixed(0)

			// Get background image
			let bg = obj.css("background-image");

			// If background image is not set, return
			if (!bg || bg == "none") return;

			// Remove url() from background image
			bg = bg.replaceAll("url(\"", "").replaceAll("\")", "");

			if (bg == obj.data("auto-size")) return;
			obj.data("auto-size", bg)

			// Create new image
			let img = new Image();
			img.src = bg;

			// When image is loaded
			img.onload = function() {
				// Get image size
				let width = img.width;
				let height = img.height;

				// Calculate new width
				let newWidth = slideHeight * width / height;

				obj.css("--slide-width", newWidth + "px")

				// Get .swiper parent of obj
				let swiper = obj.findParent(".swiper");
				if (swiper && swiper.swiper) {
					swiper.swiper.update();

					if ($(swiper).hasClass("swiper-reverse-order")) {
						swiper.swiper.slideTo(999, false, false)
					}
				}
			}
		});
	}
    interchange();

    // Analytics

    document.querySelectorAll("[href]").forEach((el) => {

        if (el.analyticsHref) return;
        el.analyticsHref = true;
        el.addEventListener("click", (e) => {

            if (!window.analytics) return;

            if (el.getAttribute("download")) {
                sendShortAnalytic("click.download", {
                    element: {
                        html: el.outerHTML,
                        text: el.innerText,
                        href: el.href,
                        target: el.target || "_self",
                        id: el.id || null,
                    }
                });
                return;
            }

            let action = "click";
            window.clickOnLink = true;
            setTimeout(() => {
                window.clickOnLink = false;
            }, 500);

            if ((el.href.startsWith("http") || el.href.startsWith("www")) && !el.href.startsWith(window.location.origin)) {
                action = "click.external";
                if (el.target != "_blank") window.clickOnLink = false;
            }
            if (el.href.startsWith("mailto")) action = "click.mailto";
            if (el.href.startsWith("tel")) action = "click.tel";
            if (el.href.startsWith("javascript")) action = "click.js";
            if (el.href.startsWith(window.location.origin)) {
                action = "click.internal";
                localStorage.setItem("fromInternal", Date.now())
            }

            console.log(`[WS] Click on link: ${action} - ${el.href}`, clickOnLink);

            sendShortAnalytic(action, {
                element: {
                    html: el.outerHTML,
                    text: el.innerText,
                    href: el.href,
                    target: el.target || "_self",
                    id: el.id || null,
                }
            })

        });

    });

    document.querySelectorAll("input").forEach((el) => {
        if (el.type == "submit" || el.type == "button" || el.type == "password") return;

        if (el.analyticsInput) return;
        el.analyticsInput = true;
        el.addEventListener("change", (e) => {
            if (!window.analytics) return;
            sendShortAnalytic("input.change", {
                element: {
                    html: el.outerHTML,
                    text: el.innerText,
                    value: el.value,
                    id: el.id || null,
                    name: el.name || null,
                    type: el.type || "text",
                    form: (el.form ? el.form.action : "/") || "/",
                }
            })
        });
    });

    document.querySelectorAll("textarea").forEach((el) => {

        if (el.analyticsTextarea) return;
        el.analyticsTextarea = true;
        el.addEventListener("change", (e) => {
            if (!window.analytics) return;
            sendShortAnalytic("input.change", {
                element: {
                    html: el.outerHTML,
                    text: el.innerText,
                    value: el.value,
                    id: el.id || null,
                    name: el.name || null,
                    type: "textarea",
                    form: (el.form ? el.form.action : "/") || "/",
                }
            })
        });
    });

    document.querySelectorAll("select").forEach((el) => {

        if (el.analyticsSelect) return;
        el.analyticsSelect = true;
        el.addEventListener("change", (e) => {
            if (!window.analytics) return;
            sendShortAnalytic("input.change", {
                element: {
                    html: el.outerHTML,
                    text: el.innerText,
                    value: el.value,
                    id: el.id || null,
                    name: el.name || null,
                    type: "select",
                    form: (el.form ? el.form.action : "/") || "/",
                }
            })
        });
    });

    document.querySelectorAll("form").forEach((el) => {

        if (el.analyticsForm) return;
        el.analyticsForm = true;
        el.addEventListener("submit", (e) => {
            if (!window.analytics) return;
            sendAnalytic("form.submit", {
                element: {
                    html: el.outerHTML,
                    text: el.innerText,
                    id: el.id || null,
                    name: el.name || null,
                    type: "form",
                    action: el.action || "/",
                    method: el.method || null,
                }
            })
        });
    });

    document.querySelectorAll("video").forEach((el) => {

        if (el.analyticsVideo) return;
        el.analyticsVideo = true;
        el.addEventListener("play", (e) => {
            if (!window.analytics) return;
            sendShortAnalytic("video.play", {
                element: {
                    html: el.outerHTML,
                    text: el.innerText,
                    id: el.id || null,
                    src: el.src || null,
                    name: el.name || null,
                }
            })
        });

        el.addEventListener("pause", (e) => {
            if (!window.analytics) return;
            sendShortAnalytic("video.pause", {
                element: {
                    html: el.outerHTML,
                    text: el.innerText,
                    id: el.id || null,
                    src: el.src || null,
                    name: el.name || null,
                }
            })
        });

        el.addEventListener("ended", (e) => {
            if (!window.analytics) return;
            sendShortAnalytic("video.ended", {
                element: {
                    html: el.outerHTML,
                    text: el.innerText,
                    id: el.id || null,
                    src: el.src || el.currentSrc || null,
                    name: el.name || null,
                }
            })
        });

    });
};

$("#cookie .close-cookie").on("pointerup", function() {
    $.cookie("COOKIE", true, 90);
    const cookie = $.cookie("COOKIE");
    if (cookie != "") {
        $("#cookie").addClass("hide");
    }
});

const cookie = $.cookie("COOKIE");
if (cookie != "") {
    $("#cookie").addClass("hide");
}


$(window).on("scroll", function() {
	loadAllMain();
});

if($('#valuation-main').length>0) {
    const slider = $('.main-slider-container');
    const elementToStick = $('#valuation-main');
    const elementToStickH = $('#valuation-main').height();
    let initialOffsetTop;
    //if (window.innerWidth <= 1023) {
    //     if ($('#valuation-main .button').length > 0 && $('.sticky-nav').length > 0) {
    //         initialOffsetTop = $('#valuation-main .button').offset().top - $('.sticky-nav').height();
    //     }
   // } else {
        initialOffsetTop = $('#valuation-main').offset().top;
  //  }
    // Nasłuchujemy zdarzenie przewijania strony i wywołujemy funkcję obsługi
    window.addEventListener('scroll', handleScroll);
    function handleScroll() {
        const scrollY = window.scrollY;
        if (scrollY >= initialOffsetTop) {
            elementToStick.addClass('sticky-valuation');
            slider.css('margin-bottom', elementToStickH + 'px');
           if($('.sticky-nav').length>0){ //} && window.innerWidth <= 1023) {
                elementToStick.css('top', $('.sticky-nav').height() + 'px');
            }
        } else {
            elementToStick.removeClass('sticky-valuation');
            slider.css('margin-bottom', '0px');
            // if(window.innerWidth <= 1023) {
            //    elementToStick.css('top', '0px');
            // }
        }
    }
}