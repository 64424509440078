import { Tag } from './tag.js';

export class Modal {
    constructor({id, classList = [], disableClose = false, stickBottom = false, onShow = () => {}, onHide = () => {}}) {
        this.id = id || `modal-${new Date().getTime()}`;
        this.modal = new Tag({classList: ["modal", ...classList], id: this.id});
        this.modal.modal = this;

        this.onShow = onShow;
        this.onHide = onHide;

        this.modal.create();

        if (disableClose) this.disableClose();
        if (stickBottom) this.stickBottom();
    }

    get() {
        return this.modal;
    }



    show() {
        $(this.modal).addClass("active");
        $(".modal-background").addClass("active");
        $(this.modal).trigger("modalshow");
        this.onShow();
    }

    hide() {
        $(this.modal).removeClass("active");
        $(".modal-background").removeClass("active");
        $(this.modal).trigger("modalhide");
        this.onHide();
    }

    remove() {
        $(this.modal).remove();
    }

    disableClose() {
        $(this.modal).addClass("disable-close");
    }

    stickBottom() {
        $(this.modal).addClass("stick-bottom");
    }

    append(content) {
        this.modal.append(content);
    }

    prepend(content) {
        this.modal.prepend(content);
    }

    html(content) {
        this.modal.html(content);
    }

}

window.Modal = Modal;